// ** React Imports
import { createElement } from "react";

// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledButtonText = styled(({ textLeft, dropdown, ...props }) =>
	createElement("span", props)
)`
	display: flex;
	align-items: content;
	justify-content: center;

	text-align: ${({ dropdown, textLeft }) =>
		dropdown || textLeft ? "left" : "center"};
`;

// #####################################################
