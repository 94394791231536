/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import defaults from "../defaults";

import { vendor } from "store/vendor";

const GET_MERGE = "groups/GET_MERGE";
const GET_MERGE_SUCCESS = "groups/GET_MERGE_SUCCESS";
const GET_MERGE_FAIL = "groups/GET_MERGE_FAIL";

const RESET_GROUP_ERROR = "groups/RESET_GROUP_ERROR";

const GET_GROUP_MEMBERS = "groups/GET_GROUP_MEMBERS";
const GET_GROUP_MEMBERS_SUCCESS = "groups/GET_GROUP_MEMBERS_SUCCESS";
const GET_GROUP_MEMBERS_FAIL = "groups/GET_GROUP_MEMBERS_FAIL";

const GET_GROUP_POSTS = "groups/GET_GROUP_POSTS";
const GET_GROUP_POSTS_SUCCESS = "groups/GET_GROUP_POSTS_SUCCESS";
const GET_GROUP_POSTS_FAIL = "groups/GET_GROUP_POSTS_FAIL";

const JOIN_GROUP = "groups/JOIN_GROUP_ID";
const JOIN_GROUP_SUCCESS = "groups/JOIN_GROUP_SUCCESS";
const JOIN_GROUP_FAIL = "groups/JOIN_GROUP_FAIL";

const LEAVE_GROUP = "groups/LEAVE_GROUP_ID";
const LEAVE_GROUP_SUCCESS = "groups/LEAVE_GROUP_SUCCESS";
const LEAVE_GROUP_FAIL = "groups/LEAVE_GROUP_FAIL";

const DELETE_REGISTRATION = "groups/DELETE_REGISTRATION";
const DELETE_REGISTRATION_SUCCESS = "groups/DELETE_REGISTRATION_SUCCESS";
const DELETE_REGISTRATION_FAIL = "groups/DELETE_REGISTRATION_FAIL";

const ADD_POST = "groups/ADD_POST";
const ADD_POST_SUCCESS = "groups/ADD_POST_SUCCESS";
const ADD_POST_FAIL = "groups/ADD_POST_FAIL";

const GET_POST_COMMENTS = "groups/GET_POST_COMMENTS";
const GET_POST_COMMENTS_SUCCESS = "groups/GET_POST_COMMENTS_SUCCESS";
const GET_POST_COMMENTS_FAIL = "groups/GET_POST_COMMENTS_FAIL";

const DELETE_POST = "groups/DELETE_POST";
const DELETE_POST_SUCCESS = "groups/DELETE_POST_SUCCESS";
const DELETE_POST_FAIL = "groups/DELETE_POST_FAIL";

const DELETE_COMMENT = "groups/DELETE_COMMENT";
const DELETE_COMMENT_SUCCESS = "groups/DELETE_COMMENT_SUCCESS";
const DELETE_COMMENT_FAIL = "groups/DELETE_COMMENT_FAIL";

const ADD_COMMENT = "groups/ADD_COMMENT";
const ADD_COMMENT_SUCCESS = "groups/ADD_COMMENT_SUCCESS";
const ADD_COMMENT_FAIL = "groups/ADD_COMMENT_FAIL";

const EDIT_POST = "groups/EDIT_POST";
const EDIT_POST_SUCCESS = "groups/EDIT_POST_SUCCESS";
const EDIT_POST_FAIL = "groups/EDIT_POST_FAIL";

const EDIT_COMMENT = "groups/EDIT_COMMENT";
const EDIT_COMMENT_SUCCESS = "groups/EDIT_COMMENT_SUCCESS";
const EDIT_COMMENT_FAIL = "groups/EDIT_COMMENT_FAIL";

const CREATE_GROUP = "groups/CREATE_GROUP";
const CREATE_GROUP_SUCCESS = "groups/CREATE_GROUP_SUCCESS";
const CREATE_GROUP_FAIL = "groups/CREATE_GROUP_FAIL";

const GROUP_AVATAR_UPLOAD_PROGRESS = "groups/GROUP_AVATAR_UPLOAD_PROGRESS";

const SET_USER_LIMIT_IN_GROUPS = "groups/SET_USER_LIMIT_IN_GROUPS";

const initialState = defaults.groups;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_MERGE:
			if (action.isFirst)
				return {
					...state,
					group: {
						...state.group,
						info: {
							...initialState.group.info,
							loading: true,
						},
						posts: {
							...initialState.group.posts,
							loading: true,
						},
						admins: {
							...initialState.group.admins,
							loading: true,
						},
					},
				};
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						loading: true,
						loaded: false,
						error: false,
					},
					posts: {
						...state.group.posts,
						loading: true,
					},
					admins: {
						...state.group.admins,
						loading: true,
					},
				},
			};
		case GET_MERGE_SUCCESS:
			const {
				group: { response: group },
				admins: { response: admins },
				posts: { response: posts },
			} = action.result.data;
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						loading: false,
						loaded: true,
						id: group.id,
						name: group.name,
						avatar: group.avatar,
						description: {
							plain: group.description_raw,
							parsed: group.description,
							cut: group.description_cut,
						},
						created: group.date_create,
						lastPost: group.last_post_data,
						groupMembers: group.participants,
						// admins,
						status: group.your_status,
						groupPosts: group.posts,
						canJoin: group.can_send_join,
						canSendJoin: group.can_send_join,
						canLeave: group.can_leave,
						limit: group.limit,
						canPost: group.can_post,
						canPostWithoutLimit: group?.can_post_without_limit,
						canLike: group.can_like,
						automaticAdd: group.automatic_add,
						sexInvite: group.sex_invite || [],
						sexPost: group.sex_post || [],
					},
					posts: {
						...state.group.posts,
						loading: false,
						loaded: true,
						currentPage: posts?.pagination?.currentpage || null,
						totalPages: posts?.pagination?.totalpages || null,
						data: posts?.posts || [],
						error: posts?.error || null,
					},
					admins: {
						...state.group.admins,
						loading: false,
						loaded: true,
						data: admins,
					},
					locationMd5Hash: action.locationMd5Hash,
				},
			};
		case GET_MERGE_FAIL:
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						error: true,
						loading: false,
						loaded: true,
						errorType: action.error,
					},
					posts: {
						...state.group.posts,
						error: true,
						loading: false,
						loaded: true,
						errorType: action.error,
					},
					admins: {
						...state.group.admins,
						error: true,
						loading: false,
						loaded: true,
						errorType: action.error,
					},
				},
			};
		case RESET_GROUP_ERROR:
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						error: false,
					},
				},
			};
		case GET_GROUP_MEMBERS:
			return {
				...state,
				group: {
					...state.group,
					members_list: {
						...state.group.members_list,
						loading: true,
					},
				},
			};
		case GET_GROUP_MEMBERS_SUCCESS:
			if (action.result.data === null) {
				return {
					...state,
					group: {
						...state.group,
						members_list: {
							...initialState.group.members_list,
							loading: false,
							loaded: true,
						},
					},
				};
			}

			const newDataGroup = action.infinityScroll
				? [
						...state.group.members_list.data,
						...(action.result.data?.users ?? []),
				  ]
				: action.result.data?.users ?? [];
			return {
				...state,
				group: {
					...state.group,
					members_list: {
						...state.group.members_list,
						loading: false,
						loaded: true,
						data: action.infinityScroll
							? newDataGroup.filter((val, id) => {
									return (
										id ===
										newDataGroup.findIndex(
											(el) => el.id === val.id
										)
									);
							  })
							: newDataGroup,
						pagination: {
							totalPages:
								action.result.data.pagination.totalpages,
							currentPage:
								action.result.data.pagination.currentpage,
						},
					},
				},
			};
		case GET_GROUP_MEMBERS_FAIL:
			return {
				...state,
				group: {
					...state.group,
					members_list: {
						...state.group.members_list,
						loading: false,
						loaded: true,
					},
				},
			};
		case GET_GROUP_POSTS:
			if (action.first)
				return {
					...state,
					group: {
						...state.group,
						posts: {
							...initialState.group.posts,
							loading: true,
						},
					},
				};
			return {
				...state,
				group: {
					...state.group,
					posts: {
						...state.group.posts,
						loading: true,
					},
				},
			};
		case GET_GROUP_POSTS_SUCCESS:
			if (action.result.data === null) {
				return {
					...state,
					group: {
						...state.group,
						posts: {
							...initialState.group.posts,
							loading: false,
							loaded: true,
						},
					},
				};
			}
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						groupPosts: action.first
							? state.group.info.groupPosts
							: state.group.info.groupPosts + 1,
					},
					posts: {
						...state.group.posts,
						loading: false,
						loaded: true,
						currentPage: action.result.data.pagination.currentpage,
						totalPages: action.result.data.pagination.totalpages,
						data: action.result.data.posts,
					},
				},
			};
		case JOIN_GROUP:
			return {
				...state,
				group: {
					...state.group,
					joinGroup: {
						...state.group.joinGroup,
						loading: true,
					},
				},
			};
		case JOIN_GROUP_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					joinGroup: {
						...state.group.joinGroup,
						loading: false,
						success: true,
					},
				},
			};
		case JOIN_GROUP_FAIL:
			return {
				...state,
				group: {
					...state.group,
					joinGroup: {
						...state.group.joinGroup,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case LEAVE_GROUP:
			return {
				...state,
				group: {
					...state.group,
					leaveGroup: {
						...state.group.leaveGroup,
						loading: true,
					},
				},
			};
		case LEAVE_GROUP_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					leaveGroup: {
						...state.group.leaveGroup,
						loading: false,
						success: true,
					},
				},
			};
		case LEAVE_GROUP_FAIL:
			return {
				...state,
				group: {
					...state.group,
					leaveGroup: {
						...state.group.leaveGroup,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case DELETE_REGISTRATION:
			return {
				...state,
				group: {
					...state.group,
					deleteRegistration: {
						...state.group.deleteRegistration,
						loading: true,
					},
				},
			};
		case DELETE_REGISTRATION_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					deleteRegistration: {
						...state.group.deleteRegistration,
						loading: false,
						success: true,
					},
				},
			};
		case DELETE_REGISTRATION_FAIL:
			return {
				...state,
				group: {
					...state.group,
					deleteRegistration: {
						...state.group.deleteRegistration,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case ADD_POST:
			return {
				...state,
				group: {
					...state.group,
					addPost: {
						...state.group.addPost,
						loading: true,
					},
				},
			};
		case ADD_POST_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					addPost: {
						...state.group.addPost,
						loading: false,
						success: true,
					},
				},
			};
		case ADD_POST_FAIL:
			return {
				...state,
				group: {
					...state.group,
					addPost: {
						...state.group.addPost,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case GET_POST_COMMENTS:
			return {
				...state,
			};
		case GET_POST_COMMENTS_SUCCESS: {
			const data = state.group.posts.data.map((post) => {
				if (post.id === action.post_id) {
					post.count_comments = action.result?.data?.total;

					if (action.more) {
						// NOTE(Przemek): Check if the comment id already exists on the list,
						// to avoid duplicated comments loaded per page/limit.

						// TODO(Przemek): Storing lookup index in the state group post(?).
						const commentsIndex = new Map();
						post.comments.map((comment, index) =>
							commentsIndex.set(comment.id, index)
						);

						const comments = post.comments;

						for (const comment of action.result?.data?.comments ??
							[]) {
							const index = commentsIndex.get(comment.id);

							if (index == null) {
								comments.push(comment);
								continue;
							}

							comments[index] = comment;
						}

						return {
							...post,
							comments,
						};
					}
					return {
						...post,
						comments: action.result?.data?.comments ?? [],
					};
				}
				return post;
			});

			return {
				...state,
				group: {
					...state.group,
					posts: {
						...state.group.posts,
						data,
					},
				},
			};
		}
		case GET_POST_COMMENTS_FAIL:
			return {
				...state,
			};
		case DELETE_POST:
			return state;
		case DELETE_POST_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						groupPosts: state.group.info.groupPosts - 1,
					},
				},
			};

		case DELETE_POST_FAIL:
			return state;
		case DELETE_COMMENT:
			return state;
		case DELETE_COMMENT_SUCCESS:
			return state;
		case DELETE_COMMENT_FAIL:
			return state;
		case ADD_COMMENT:
			return {
				...state,
				group: {
					...state.group,
					addComment: {
						...initialState.group.addComment,
						loading: true,
					},
				},
			};
		case ADD_COMMENT_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					addComment: {
						...state.group.addComment,
						loading: false,
						success: true,
						error: false,
					},
				},
			};
		case ADD_COMMENT_FAIL:
			return {
				...state,
				group: {
					...state.group,
					addComment: {
						...state.group.addComment,
						loading: false,
						error: true,
					},
				},
			};
		case EDIT_POST:
			return {
				...state,
				group: {
					...state.group,
					editPost: {
						...initialState.group.editPost,
						loading: true,
						value: action.data.text,
					},
				},
			};
		case EDIT_POST_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					editPost: {
						...state.group.editPost,
						loading: false,
						success: true,
					},
				},
			};
		case EDIT_POST_FAIL:
			return {
				...state,
				group: {
					...state.group,
					editPost: {
						...initialState.group.editPost,
						loading: false,
						error: true,
					},
				},
			};
		case EDIT_COMMENT:
			return {
				...state,
				group: {
					...state.group,
					editComment: {
						...initialState.group.editComment,
						loading: true,
						value: action.data.text,
					},
				},
			};
		case EDIT_COMMENT_SUCCESS:
			return {
				...state,
				group: {
					...state.group,
					editcomment: {
						...state.group.editComment,
						loading: false,
						success: true,
					},
				},
			};
		case EDIT_COMMENT_FAIL:
			return {
				...state,
				group: {
					...state.group,
					editComment: {
						...initialState.group.editComment,
						loading: false,
						error: true,
					},
				},
			};
		case CREATE_GROUP:
			return {
				...state,
				group: {
					...state.group,
					avatarUploadProgress: 0,
				},
			};
		case GROUP_AVATAR_UPLOAD_PROGRESS:
			return {
				...state,
				group: {
					...state.group,
					avatarUploadProgress: action.avatarUploadProgress,
				},
			};
		case SET_USER_LIMIT_IN_GROUPS:
			return {
				...state,
				group: {
					...state.group,
					info: {
						...state.group.info,
						limit: action.payload,
					},
				},
			};

		default:
			return state;
	}
}

/**
 * Pobiera członków grupy.
 * @api GET getGroupUser
 * @param {Number} id identyfikator grupy
 * @param {Number} page numer strony
 * @param {String} sex szukana płeć
 */

export function getGroupMembersById(id, page, sex, infinityScroll = false) {
	return {
		types: [
			GET_GROUP_MEMBERS,
			GET_GROUP_MEMBERS_SUCCESS,
			GET_GROUP_MEMBERS_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user`, {
				params: {
					id,
					page,
					sex,
					limit: 30,
				},
			}),
		infinityScroll,
	};
}

/**
 * MERGE
 *
 * @param {*} id
 * @param {*} isAuth
 * @param {*} isFirst
 * @returns
 */
export function getGroupMergeById({
	id,
	isAuth,
	isFirst,
	locationMd5Hash = "",
}) {
	return {
		types: [GET_MERGE, GET_MERGE_SUCCESS, GET_MERGE_FAIL],
		promise: (client) =>
			client.post(
				isAuth ? "/merge/bulk" : "/merge/bulk/unauthorized",
				JSON.stringify([
					{
						key: "group",
						url: `group${isAuth ? "" : "/unauthorized"}`,
						args: {
							id,
						},
					},
					{
						key: "admins",
						url: "group-user/admins",
						args: {
							id,
						},
					},
					{
						key: "posts",
						url: `group-content${isAuth ? "" : "/unauthorized"}`,
						args: {
							id,
							limit: 5,
							comment: "true",
						},
					},
				])
			),
		isFirst,
		locationMd5Hash,
	};
}

export function resetGroupError() {
	return {
		type: RESET_GROUP_ERROR,
	};
}

/**
 * Pobiera posty w grupie.
 * @api GET getGroupPosts
 * @param {Number} id identyfikator grupy
 * @param {Number} page numer strony
 * @param {Number} limit limit postów na stronie
 *  * @param {String} sex plec szukanych postów
 * @param {Boolean} isAuth czy user jest autoryzowany
 */

export function getGroupPosts({
	id,
	page,
	limit = 10,
	sex = "any",
	isAuth = true,
	first,
}) {
	return {
		types: [GET_GROUP_POSTS, GET_GROUP_POSTS_SUCCESS, GET_GROUP_POSTS_FAIL],
		promise: (client) =>
			client.get(`/group-content${isAuth ? "" : "/unauthorized"}`, {
				params: {
					id,
					page,
					sex,
					comment: true,
					limit,
				},
			}),
		first,
	};
}

/**
 * Dołącza użytkownika do grupy
 * @api POST joinToGroup
 * @param {Number} id identyfikator grupy
 */

export function joinGroup(id) {
	return {
		types: [JOIN_GROUP, JOIN_GROUP_SUCCESS, JOIN_GROUP_FAIL],
		promise: (client) => client.post(`/group-user?id=${id}`),
	};
}

/**
 * Usuwa użytkownika z grupy
 * @api DELETE leaveGroup
 * @param {Number} id identyfikator grupy
 */

export function leaveGroup(id) {
	return {
		types: [LEAVE_GROUP, LEAVE_GROUP_SUCCESS, LEAVE_GROUP_FAIL],
		promise: (client) =>
			client.delete(`/group-user/leave`, {
				params: {
					id,
				},
			}),
	};
}

/**
 * Usuwa użytkownika z listy oczekujących do przyjęcia do grupy
 * @api DELETE deleteGroupsSendInvite
 * @param {Number} id identyfikator grupy
 */

export function deleteRegistration(id) {
	return {
		types: [
			DELETE_REGISTRATION,
			DELETE_REGISTRATION_SUCCESS,
			DELETE_REGISTRATION_FAIL,
		],
		promise: (client) =>
			client.delete(`/group/send-invite`, {
				params: {
					id,
				},
			}),
	};
}

/**
 * Dodaje post do grupy
 * @api POST addGroupPost
 */

export function addPost(group_id, text) {
	const data = { group_id, text };
	return {
		types: [ADD_POST, ADD_POST_SUCCESS, ADD_POST_FAIL],
		promise: (client) => client.post("/group-content", data),
	};
}

/**
 * Pobiera komentarze do posta
 * @api GET getGroupPostComments
 * @param {Number} groupId identyfikator grupy
 * @param {Number} postId identyfikator postu
 * @param {Number} page numer strony
 * @param {Number} limit limit postów na stronie
 * @param {Boolean} more concatenate requested comments to the current post comments list (default: false, to replace comments list with requested comments)
 */

export function getPostComments({
	groupId,
	postId,
	page = 1,
	limit = 5,
	more = false,
}) {
	return {
		types: [
			GET_POST_COMMENTS,
			GET_POST_COMMENTS_SUCCESS,
			GET_POST_COMMENTS_FAIL,
		],
		promise: (client) =>
			client.get(`/group-content/comment`, {
				params: {
					id: groupId,
					postId,
					page,
					limit,
				},
			}),
		post_id: postId,
		more,
	};
}

/**
 * Usuwa post
 * @api DELETE deleteGroupPost
 * @param {Number} groupId identyfikator grupy
 * @param {Number} postId identyfikator postu
 */

export function deletePost(groupId, postId) {
	return {
		types: [DELETE_POST, DELETE_POST_SUCCESS, DELETE_POST_FAIL],
		promise: (client) =>
			client.delete(`/group-content`, {
				params: {
					id: groupId,
					postId,
				},
			}),
		post_id: postId,
	};
}

/**
 * Usuwa komentarz
 * @api DELETE deleteGroupPostComment
 * @param {Number} groupId identyfikator grupy
 * @param {Number} postId identyfikator postu
 * @param {Number} commentId identyfikator komentarza
 */

export function deleteComment(groupId, postId, commentId) {
	return {
		types: [DELETE_COMMENT, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAIL],
		promise: (client) =>
			client.delete(`/group-content/comment`, {
				params: {
					id: groupId,
					postId,
					commentId,
				},
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
		post_id: postId,
		comment_id: commentId,
	};
}

/**
 * Dodaje komentarz
 * @api DELETE deleteGroupPostComment
 * @param {Object} data id grupy/id postu/treść komentarza
 */

export function addComment(data) {
	return {
		types: [ADD_COMMENT, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAIL],
		promise: (client) => client.post(`/group-content/comment`, data),
	};
}

/**
 * Edytuje post w grupie
 * @api POST editGroupPost
 * @param {Object} data id grupy/id postu/nowa treść postu
 */

export function editPost(data) {
	return {
		types: [EDIT_POST, EDIT_POST_SUCCESS, EDIT_POST_FAIL],
		promise: (client) => client.put("/group-content", data),
		data,
	};
}

/**
 * Dodaje post do grupy
 * @api POST editGroupcomment
 * @param {Object} data id grupy/id postu/id komentarza/nowa treść komentarza
 */

export function editComment(data) {
	return {
		types: [EDIT_COMMENT, EDIT_COMMENT_SUCCESS, EDIT_COMMENT_FAIL],
		promise: (client) => client.put("/group-content/comment", data),
		data,
	};
}

/**
 * Tworzy nową grupe
 * @api POST createNewGroup
 * @param {Object} data
 */
export function createGroup(data, avatar) {
	const formData = new FormData();

	formData.append("data", JSON.stringify(data));
	if (avatar) formData.append("avatar", avatar);

	return {
		types: [CREATE_GROUP, CREATE_GROUP_SUCCESS, CREATE_GROUP_FAIL],
		promise: (client) =>
			client
				.get("/system/token")
				.then(({ data: { token: _uploadToken } }) => {
					formData.append("token", _uploadToken);
					return client.post("/group", formData, {
						onUploadProgress: (e) => {
							vendor.store.dispatch(
								setGroupAvatarUploadProgress(
									Math.round((e.loaded * 100) / e.total)
								)
							);
						},
					});
				}),
	};
}

export function setGroupAvatarUploadProgress(progress) {
	return {
		type: GROUP_AVATAR_UPLOAD_PROGRESS,
		avatarUploadProgress: progress,
	};
}

export const setUserLimitInGroups = (limit) => ({
	type: SET_USER_LIMIT_IN_GROUPS,
	payload: limit,
});
