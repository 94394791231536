// ** Third Party Componentsor from "color-js";
import styled from "styled-components";

// #####################################################

export const StyledLoadingContainer = styled("span")`
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

// #####################################################
